var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "search-attr" },
        [
          _c(
            "div",
            [
              _c("a-input", {
                staticStyle: { width: "150px" },
                attrs: { allowClear: "", size: "small", placeholder: "标题" },
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              }),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    showSearch: "",
                    size: "small",
                    placeholder: "分类类型"
                  },
                  model: {
                    value: _vm.params.positionType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "positionType", $$v)
                    },
                    expression: "params.positionType"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: "new_index" } }, [
                    _vm._v("拍品分类")
                  ]),
                  _c(
                    "a-select-option",
                    { attrs: { value: "version_picture" } },
                    [_vm._v("图库分类")]
                  ),
                  _c(
                    "a-select-option",
                    { attrs: { value: "v8_guqian_category" } },
                    [_vm._v("首页古钱币")]
                  ),
                  _c(
                    "a-select-option",
                    { attrs: { value: "v8_machine_category" } },
                    [_vm._v("首页机制币")]
                  )
                ],
                1
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.openModal({})
                }
              }
            },
            [_vm._v("新增分类")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "Image",
                fn: function(row) {
                  return _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100px",
                        height: "100px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "100px"
                        },
                        attrs: { src: row }
                      })
                    ]
                  )
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticClass: "map-margin",
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 1000 },
          on: { ok: _vm.checkForms, cancel: _vm.cancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "400px", "overflow-y": "auto" } },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.forms, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "分类类型", prop: "positionType" } },
                    [
                      _c(
                        "a-select",
                        {
                          ref: "addse",
                          attrs: {
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.changeSelect },
                          model: {
                            value: _vm.forms.positionType,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "positionType", $$v)
                            },
                            expression: "forms.positionType"
                          }
                        },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: "new_index" } },
                            [_vm._v("拍品分类")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "version_picture" } },
                            [_vm._v("图库分类")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "v8_guqian_category" } },
                            [_vm._v("首页古钱币")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "v8_machine_category" } },
                            [_vm._v("首页机制币")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "刷选类型", prop: "customType" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "default-value": "search",
                            showSearch: "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.forms.customType,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "customType", $$v)
                            },
                            expression: "forms.customType"
                          }
                        },
                        [
                          _c("a-select-option", { attrs: { value: "1" } }, [
                            _vm._v("平台大分类")
                          ]),
                          _c("a-select-option", { attrs: { value: "2" } }, [
                            _vm._v("币种组合")
                          ]),
                          _c("a-select-option", { attrs: { value: "4" } }, [
                            _vm._v("朝代组合")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "类别名称", prop: "customName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入类别名称" },
                        model: {
                          value: _vm.forms.customName,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "customName", $$v)
                          },
                          expression: "forms.customName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "类型值", prop: "customValue" } },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "请输入对应类型值" },
                        model: {
                          value: _vm.forms.customValue,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "customValue", $$v)
                          },
                          expression: "forms.customValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "排序" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "越小越靠前" },
                        model: {
                          value: _vm.forms.customSort,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "customSort", $$v)
                          },
                          expression: "forms.customSort"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }